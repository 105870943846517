@charset "UTF-8";
@import "ap_preaset";

/*****************
*** common.css ***
*****************/


/* default */
.en body,
.en h1,
.en h2,
.en h3,
.en h4,
.en h5,
.en h6 {
	letter-spacing: toRem(-0.2);
}

.en .fltConVtc .middle .dateWrapper .slctCon {
	top: toRem(-290);
}
.en .fltConVtc .middle .passengers .slctCon{
	top: toRem(-346);
}
@include rwd('ul'){
	.en .fltConVtc .middle .dateWrapper .slctCon {
		top: toRem(-262);
	}
	.en .fltConVtc .middle .passengers .slctCon{
		top: toRem(-298.5);
	}
}
@include rwd('xl'){
	.en .fltConVtc .middle .dateWrapper .slctCon {
		top: toRem(-268);
	}
	.en .fltConVtc .middle .passengers .slctCon{
		top: toRem(-306.5);
	}
}

@include rwd('sm') {
	.en .fltConVtc .middle .passengers .slctCon {
		top: toRem(0);
	}
}
/* taskButton */
.en .taskButton {
	white-space: nowrap;
}
.en .taskButton.mealLnBtn {
    white-space: initial;
}

/* cautionWrap */
.en .cautionWrap .list li {
	font-size: toRem(14);
}

/* badge */
.en .badgeArrow p {
	white-space: nowrap;
}
@include rwd('sm'){
	/* daterangeWrap */
	.en .daterangeWrap .priceWrap .left > ul > li.sub {
		align-items: center;
	}
	.en .daterangeWrap .bottomArea .btnTwin .taskButton:last-child {
		min-width: toRem(155);
	}
}


/****************
*** style.css ***
****************/


/* sub */
.en .subTit {
    font-size: toRem(30);
}
.en .subMdTit {
    font-size: toRem(22);
}
.en .subSmTit {
    font-size: toRem(18);
}


/* header */


/* main */
.en .fltConVtc h3 {
	margin-bottom: toRem(48);
	font-size: toRem(27);
}
.en .daterangeWrap .priceWrap .sub .br {
    display: block;
}

/* 왕복,편도 버튼 */
.en .homeTicket .tripType {
	display: block;
	margin-bottom:toRem(8);
}
.en .tripType:after {
	content: "";
	display: block;
	clear: both;
}
.en .tripType .typeBtn {
	float: left;
}
.en .tripType .toggleWrap {
	margin-top: toRem(3);
	float: right;
}

@include rwd('xl'){
	.en .tripType .toggleWrap {
		margin-top: toRem(10);
	}
}

/*탑승객 입력*/
.en .inputWrap .label {
	position: relative;
}
.en .inputWrap .label .ftO {
	position: absolute;
	left: toRem(-10);
}

/*부가 서비스*/
.en .contWrapper .receipt {
	top: toRem(150);
}
.en .payReceipt .expWrapper .row .label {
	width: 57%;
}
.en .payReceipt .expWrapper .row .value {
	width: calc( 43% - #{toRem(5)});
	text-align: right;
}
.en .taskButton.btnBggPcs {
	white-space: normal;
	height: auto;
	padding: toRem(10) toRem(5);
}

/* footer */
.en footer .left > .box {
	padding-right: toRem(60);
}
.en footer .left > .box:last-child {
	padding-right: 0;
}
.en footer .left li {
	line-height: 1.5em;
}
.en footer .right {
	margin-left: toRem(60);
}
.en footer .right .support .timeWrapper {
	flex-direction: column;
}
.en footer .bottom .content ul li span {
	font-size: toRem(13);
}

/* 예매 공통 */
.en .pBtmWrap .lg_btn {
	width: toRem(85);
}
.en .pBtmWrap .lg_btn .taskButton {
	padding: 0 toRem(5);
	font-size: toRem(14);
}

@include rwd('ul'){
	.home .en .tripType{
		margin-bottom:0;
	}
	.en .tripType button{
		font-size:toRem(12);
	}
	.en .toggleWrap .container .content{
		font-size:toRem(11);
	}
	.en .slctBox.vtc button.val{
		font-size:toRem(15);
	}
	.en .fltConVtc h3{
		font-size:toRem(25);
		margin-bottom:toRem(28);
	}
	.fltConVtc .bottom{
		margin-top:toRem(20);
	}
	.en .fltConVtc .bottom .taskButton .iconWrap{
		font-size:toRem(15);
	}
	.en .fltConVtc .bottom .taskButton .iconWrap .icon{
		width:toRem(18);
		height:toRem(18);
		background-size:toRem(18);
	}
}

@include rwd('xl'){
	.en .tripType button{
		font-size:toRem(12);
	}
	.en .toggleWrap .container .content{
		font-size:toRem(11);
	}
	.home .en .tripType{
		margin-bottom:0;
	}
	.en .slctBox.vtc button.val{
		font-size:toRem(15);
	}
	.en .fltConVtc h3{
		font-size:toRem(24);
		margin-bottom:toRem(32);
	}
	.fltConVtc .bottom{
		margin-top:toRem(20);
	}
	.en .fltConVtc .bottom .taskButton .iconWrap{
		font-size:toRem(15);
	}
	.en .fltConVtc .bottom .taskButton .iconWrap .icon{
		width:toRem(18);
		height:toRem(18);
		background-size:toRem(18);
	}
}

@include rwd('md'){

	/* footer */
	.en footer .bottom .content ul li span {
		margin-bottom: toRem(10);
		line-height: 1.4em;
	}
}

//@media only screen and (max-width: 799px) {
@include rwd('md'){
	.en .layout.frLayout {
		padding-bottom: toRem(300);
	}
	/* 예매 공통 */
	.en .fltTab .underWrap {
		height: auto;
	}
	.en .underWrap .cont {
		padding: toRem(20);
		display: block;
	}
	.en .underWrap .prcstpBtn {
		display: block;
	}
	.en .underWrap .prmCode .rounded {
        min-width: toRem(170);
        margin: 0 auto;
    }
    .en .underWrap .prmCode .rounded.on {
		width: 100%;
		margin: 0;
	}
	.en .underWrap .prmCode .dftBtn .text {
	    display: flex;
	    align-items: center;
	    justify-content: space-between;
	}
	.en .underWrap .prmCode .dftBtn .text .name {
		width: calc(100% - #{toRem(100)});
		max-width: unset;
		margin-right: 0;
	}
	.en .underWrap .prmCode .dftBtn .text .code {
		width: toRem(90);
		display: inline-block;
		text-align: right;
	}
	.en .underWrap .prmCode .prmDel {
		margin-top: toRem(13);
	}
	.en .prcRgt {
		margin: toRem(20) 0 toRem(10);
		flex-direction: column;
	}
	.en .prcRgt p {
		width: 100%;
	}
	.en .prcRgt h3 {
		width: 100%;
		margin-left: 0;
		text-align: right;
	}
	.en .underWrap .stpBtn .taskButton {
		width: 100%;
	}
}
@include rwd('sm'){
	/* sub */
	.en .subTit {
	    font-size: toRem(26);
	}
	.en .subMdTit {
	    font-size: toRem(18);
	}
	.en .subSmTit {
	    font-size: toRem(16);
	}

	/* 탑승객입력 */
	.en .inputWrap .row.pntClct .value {
		height: auto;
		padding: toRem(10) 0 toRem(10) 0;
	}
	.en .inputWrap .pntClct .rdoBox {
		margin-bottom: toRem(5);
		display: block;
	}
	.en .inputWrap .pntClct .rdoBox:last-child {
		margin-bottom: 0;
	}
}

@media only screen and (max-width: 359px) {
	/* footer */
	.en footer .right .support .timeWrapper {
		display: block;
	}
}


/*****************
*** style2.scss ***
*****************/

/* 예매 */
.en .agrBtnWrap > button {
    height: toRem(80);
}
.en .icon.arrow.lngTxtarw {
    position: absolute;
    right: toRem(10);
}

/* 조회/체크인 */
.en .dacWrap .realTimeChk .secSlt .asOf {
	width: toRem(250);
}
.en .dacWrap .realTimeChk .secSlt .slctBox {
	width: calc(100% - #{toRem(300)});
}

/* 출도착 조회 */
.en .dacWrap .secSlt {
	min-height: toRem(50);
	height: auto;
	padding-top: toRem(5);
	padding-bottom: toRem(5);
}
.en .dacWrap .secSlt .arrBar {
	margin: 0 toRem(10);
}
.en .dacWrap .checkRes .lineTb tr td {
	word-break: break-all;
}

/* 운항 스케쥴 */
.en .schLine {
	width: toRem(20);
	margin: 0;
}

/* 운임안내 */
.en .fareWrap .classArea .iconBox p {
	width: toRem(150);
}
@include rwd('sm'){
	.en .fareWrap .classArea .illustBox {
		align-items: flex-start;
	}
	.en .fareWrap .classArea .iconBox {
		min-height: toRem(220);
	}
}

/* 프레미아 멤버스 */
.en .memWrap .spBanner .left p {
	text-align: left;
}

/* 항공교통이용자 피해구제계획 */
.en .termStepWrap .title {
	padding-bottom: 0;
	font-size: 1.4rem;
	font-weight: 200;
	line-height: 1.5em;
	letter-spacing: -0.05em;
}
.en .termStepWrap .num {
    top: toRem(5);
    left: toRem(15);
    color: $ap-solid-darkblue;
    font-size: 1.5rem;
    font-weight: 500;
    text-align: left;
}
.en .termStepWrap .user {
	bottom: toRem(5);
	font-size: 1.3rem;
}
@include rwd('sm'){
	/* 조회/체크인 */
	.en .dacWrap .realTimeChk .secSlt .asOf,
	.en .dacWrap .realTimeChk .secSlt .slctBox {
		width: 50%;
	}
	.en .dacWrap .realTimeChk .secSlt .asOf {
		text-align: left;
	}
	.en .dacWrap .secSlt {
		justify-content: start;
	}
	.en .dacWrap .checkRes .lineTb tr td .badge {
		height: auto;
		display: inline-block;
		text-align: center;
	}

	/* 항공교통이용자 피해구제계획 */
	.en .termStepWrap:before {
	    top: toRem(80);
	}
	.en .termStepWrap:after {
    	top: toRem(281);
	}
	.en .termStepWrap .step {
	    width: toRem(160);
	    height: toRem(160);
	}
	.en .termStepWrap .title {
		padding: 0 toRem(10) 0 toRem(10);
		font-size: 1.2rem;
		line-height: 1.3em;
	}
	.en .termStepWrap .arrow {
		top: toRem(170);
	    left: toRem(130);
	    right: calc(50% + #{toRem(30)});
	    transform: rotate(-50deg);
	}
	.en .termStepWrap .num {
	    left: toRem(10);
	    font-size: 1.4rem;
	}
	.en .termStepWrap .user {
		font-size: 1.1rem;
	}
}

@media only screen and (max-width: 599px) {
	/* 항공교통이용자 피해구제계획 */
	.en .termStepWrap:after {
	    top: auto;
	    bottom: toRem(80);
	}
	.en .termStepWrap .step {
	    width: toRem(140);
	    height: toRem(140);
	}
	.en .termStepWrap .arrow {
	    top: toRem(160);
	    left: toRem(150);
	    right: toRem(160);
	    transform: rotate(-30deg);
	}
}
@include rwd('xs'){
	/* 조회/체크인 */
	.en .dacWrap .fNumChk .secSlt .fName {
		width: auto;
	}
	.en .dacWrap .fNumChk .secSlt .fNum {
		width: calc(100% - #{toRem(35)});
	}

	/* 항공교통이용자 피해구제계획 */
	.en .termStepWrap .arrow {
		left: toRem(130);
        right: toRem(130);
        transform: rotate(-40deg);
    }
}
